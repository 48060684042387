import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { fadeInUpAnimation } from '../../_animation/fade-in-up.animation';

import { AppConfigService } from '../../_services/app-config.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  ActivatedRoute,
  RouterStateSnapshot, ActivatedRouteSnapshot
} from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-orderstatus',
  templateUrl: './orderstatus.component.html',
  styleUrls: ['./orderstatus.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUpAnimation],
  host: {
    class: 'container-fluid'
  },
})
export class OrderstatusComponent implements OnInit {
  apiurl = AppConfigService.settings.apiServer.apiurl;
  ClinicName: string;
  ClinicData: object[] = [];
  ClinicImageLogo: string = "";
  isMobile;
  AppData = {};
  PaymentMessage = "";
  PaymentStatus = "";
  IsGetback: string = "";

  GetBackLogo: string = AppConfigService.settings.apiServer.apiurl + "/api/assets/gray-orange-logo.png";
  constructor(private deviceService: DeviceDetectorService, private route: ActivatedRoute, private http: HttpClient) {

  }

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();


    this.route["params"].subscribe(para => {
      this.PaymentStatus = para["type"];
      if (para["type"] == "success") {

        this.PaymentMessage = "Thank You, Your Booking is Successful";
      } else {
        this.PaymentMessage = "Payment Failed";
      };
    })

    this.IsGetback = AppConfigService.settings.IsGetBack;
    if (AppConfigService.settings.clinic.img && AppConfigService.settings.clinic.img != "") {
      this.ClinicImageLogo = this.apiurl + 'api/' + AppConfigService.settings.clinic.img;
    }

    this.ClinicName = AppConfigService.settings.clinic.name;
    const listdata = this.route.snapshot.data['status'];
    if (listdata["appdata"]) {
      listdata["appdata"].subscribe(resp => {
        //  console.log(resp);
        this.AppData = resp;
      });
    }
  }

}
