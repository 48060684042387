import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {  BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";

import { DeviceDetectorModule } from "ngx-device-detector";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
 
 

import { Token } from "./_helper/token.interceptor";
import { InarrayPipe } from "./_helper/inarray.pipe";

import { StripeModule } from "stripe-angular";
import { OrderstatusComponent } from "./pages/orderstatus/orderstatus.component";

import { AppConfigService } from "./_services/app-config.service";

 

export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
  
    InarrayPipe,
    OrderstatusComponent,
  
   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: "never" }),
    FormsModule,
    BrowserAnimationsModule,
     
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: Token, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
