import { Routes } from "@angular/router";
import { Initlist } from "./_resolver/initlist.resolve";
import { OrderstatusR } from "./_resolver/orderstatus.resolve";
import { OrderstatusComponent } from "./pages/orderstatus/orderstatus.component";
const ɵ0 = () => import("./pages/steps/steps.module.ngfactory").then(m => m.StepsModuleNgFactory);
const routes = [
    {
        path: "",
        loadChildren: ɵ0
    },
    /* {
       path: "",
       component: HomeComponent,
       resolve: {
         list: Initlist,
       },
     },*/ {
        path: "order/:type",
        component: OrderstatusComponent,
        resolve: { list: Initlist, status: OrderstatusR },
    },
    { path: "**", redirectTo: "" },
];
export class AppRoutingModule {
}
export { ɵ0 };
