import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
 

import { Initlist } from "./_resolver/initlist.resolve";
import { OrderstatusR } from "./_resolver/orderstatus.resolve";
import { DateAvailabel } from "./_resolver/dateaval.resolve";
import { TimeAvailabel } from "./_resolver/timeaval.resolve";
import { SuccessAppointment } from "./_resolver/success.resolve";

import { OrderstatusComponent } from "./pages/orderstatus/orderstatus.component"; 
const routes: Routes = [
  {
    path:"",
    loadChildren:()=>import('./pages/steps/steps.module').then(m=>m.StepsModule)
      },
 /* {
    path: "",
    component: HomeComponent,
    resolve: {
      list: Initlist,
    },
  },*/ {
    path: "order/:type",
    component: OrderstatusComponent,
    resolve: { list: Initlist, status: OrderstatusR },
  },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [Initlist],
})
export class AppRoutingModule {}
