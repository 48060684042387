import { Injectable } from "@angular/core";
import SecureStorage from "secure-web-storage";
import { BookingParam } from "../_helper/bookingdatails";
import { BehaviorSubject, Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class StepperService {
  public StepprtSubject: BehaviorSubject<any>;
  public Stepper: Observable<BookingParam>;
  constructor() {
    this.StepprtSubject = new BehaviorSubject<BookingParam>(null);
    this.Stepper = this.StepprtSubject.asObservable();
  }
  public get StepperLabels(): BookingParam {
    return this.StepprtSubject.value;
  }
  public setLabels(value) {
    this.StepprtSubject.next(value);
  }
}
