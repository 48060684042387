import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../_services/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
import * as i3 from "../_services/clinic.service";
export class OrderstatusR {
    constructor(router, http, cs) {
        this.router = router;
        this.http = http;
        this.cs = cs;
        this.initclinik = AppConfigService.settings.clinic.name;
        this.initpk = AppConfigService.settings.clinic.pk;
    }
    resolve(route, state) {
        var repdata = {};
        if (route["params"]["type"]) {
            if (route["params"]["type"] == "success") {
                repdata["status"] = "success";
                if (route["queryParams"]["orderid"] && route["queryParams"]["apid"]) {
                    repdata["appdata"] = this.cs.updateingleApp(route["queryParams"]["apid"], route["queryParams"]["orderid"]);
                }
            }
            else {
                repdata["status"] = "failed";
                repdata["appdata"] = this.cs.getsingleApp(route["queryParams"]["apid"]);
            }
        }
        return repdata;
    }
}
OrderstatusR.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderstatusR_Factory() { return new OrderstatusR(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.ClinicService)); }, token: OrderstatusR, providedIn: "root" });
