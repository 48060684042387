<div class="row justify-content-center min-vh-100">
  <div class="col-lg-8 col-md-10 min-vh-100">
    <div
      class="row no-gutters stepper justify-content-center is-loaded"
      #stepper
    >
      <div class="col-md-4 border-right">
        <div class="step-main px-3 pt-3">
          <div class="text-center mt-3 mb-4">
            <img
              [src]="GetBackLogo"
              class="img-fluid"
              style="max-height: 25px"
            />
          </div>
          <div class="text-center mb-2 mb-md-3 mt-md-4">
            <img [src]="ClinicImageLogo" class="img-fluid" />
          </div>
          <h5 class="text-center text-secondary d-none pb-2 fs-18">
            {{ ClinicName | titlecase }}
          </h5>

          <div
            class="
              step-desc
              d-flex
              flex-column
              align-items-center
              justify-content-center
              pb-4
            "
          >
            <div class="desc-group"></div>
            <div class="desc-group">
              <h3 class="desc-title fs-18"></h3>
            </div>
            <div class="desc-group">
              <p class="fs-13 text-center"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card border-0" [ngClass]="{ 'min-vh-100': isMobile }">
          <div class="card-header bg-transparent">
            <h3
              class="card-title fs-18 mb-0 desc-title"
              [ngClass]="{
                'text-success': PaymentStatus == 'success',
                'text-danger': PaymentStatus == 'failed'
              }"
            >
              {{ PaymentMessage }}
            </h3>
          </div>
        </div>

        <div class="card-body px-0 px-md-3 py-0 position-relative">
          <div
            class="progress position-absolute rounded-0 top-0 start-0 w-100"
            style="height: 8px; left: 0px"
          >
            <div
              class="progress-bar bg-warning"
              role="progressbar"
              style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <div
            class="w-100 h-100 d-flex justify-content-center align-items-center"
            style="z-index: 100; background-color: rgba(255, 255, 255, 0.65)"
          >
            <div class="pt-3 w-100">
              <div class="pb-4 pt-2 h-100 in">
                <div class="mb-4 alert alert-primary-alt ls-12 fs-13 d-none">
                  One of our representative will get back to you shortly. Here
                  are the booking details.
                </div>

                <div class="mb-4 alert alert-primary-alt ls-12 fs-13 d-none">
                  One of our representative will get back to you shortly. Here
                  are the booking details.
                </div>

                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col-4 mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Service Type</label>
                    {{ AppData["ServiceType"] }}
                  </div>
                  <div class="col-4 mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Patient Type</label>
                    {{ AppData["CustomerType"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1">Appointment Type</label
                    >{{ AppData["Apptype"] }}
                  </div>
                </div>

                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Practitioner</label>
                    {{ AppData["PractitionerName"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment Date</label
                    >
                    {{ AppData["appdate"] }}
                  </div>
                </div>

                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment Start Time</label
                    >{{ AppData["stime"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1"
                      >Appointment End Time</label
                    >{{ AppData["etime"] }}
                  </div>
                </div>
                <h5 class="pb-1 text-info border-bottom fs-14 mb-3">
                  Contact Info
                </h5>
                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">First Name</label>
                    {{ AppData["FirstName"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1">Last Name</label>
                    {{ AppData["LastName"] }}
                  </div>
                </div>
                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Mobile phone</label>
                    {{ AppData["PhoneNumber"] }}
                  </div>
                  <div class="col mb-2 d-flex flex-column">
                    <label class="text-muted fs-13 mb-1">Email address</label>
                    {{ AppData["EmailId"] }}
                  </div>
                </div>
                <div class="row fs-14 mb-3 border-bottom">
                  <div class="col mb-2 d-flex flex-column border-right">
                    <label class="text-muted fs-13 mb-1">Comments</label>
                    {{ AppData["notes"] }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
