import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject, throwError, forkJoin } from 'rxjs';
import { mergeMap, take, catchError, map, retry } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';

@Injectable({
	providedIn: 'root'
})
export class ClinicService {

	apiurl = AppConfigService.settings.apiServer.apiurl;
	constructor(private http: HttpClient) { }
	getservices(): Observable<any> {

		var url = this.apiurl + "appointment/services/";
		var urlP = this.apiurl + "appointment/practitioners/";
		const response1 = this.http.get(url);
		const response2 = this.http.get(urlP);
		return forkJoin([response1, response2]);

	}

	updateingleApp(pk, orderId): Observable<any> {
		var url = this.apiurl + "appointment/appstatus/" + pk;
		var formData: any = new FormData();
		formData.append('orderid', orderId);
		formData.append('pkey', pk);
		return this.http.post(url, formData);
	}
	getsingleApp(pk): Observable<any> {
		var url = this.apiurl + "appointment/singleapp/" + pk;

		return this.http.get(url);
	}


}
