import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class StepperService {
    constructor() {
        this.StepprtSubject = new BehaviorSubject(null);
        this.Stepper = this.StepprtSubject.asObservable();
    }
    get StepperLabels() {
        return this.StepprtSubject.value;
    }
    setLabels(value) {
        this.StepprtSubject.next(value);
    }
}
StepperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StepperService_Factory() { return new StepperService(); }, token: StepperService, providedIn: "root" });
