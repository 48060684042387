import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '../_services/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/clinic.service";
import * as i3 from "@angular/common/http";
export class Initlist {
    constructor(router, CS, http) {
        this.router = router;
        this.CS = CS;
        this.http = http;
        this.initclinik = AppConfigService.settings.clinic.name;
        this.initpk = AppConfigService.settings.clinic.pk;
    }
    resolve(route, state) {
        return this.CS.getservices();
    }
}
Initlist.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Initlist_Factory() { return new Initlist(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ClinicService), i0.ɵɵinject(i3.HttpClient)); }, token: Initlist, providedIn: "root" });
