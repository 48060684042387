import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AppConfigService {
    constructor(http) {
        this.http = http;
    }
    load() {
        const jsonFile = 'assets/config/config.json?t=' + Date.now();
        return new Promise((resolve, reject) => {
            this.http.get(jsonFile, { headers: { skip: "true" } }).toPromise().then((response) => {
                AppConfigService.settings = response;
                resolve();
            }).catch((response) => {
                reject(`Could not load the config file`);
            });
        });
    }
}
AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(i0.ɵɵinject(i1.HttpClient)); }, token: AppConfigService, providedIn: "root" });
